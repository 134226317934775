import React from 'react';
import PageLayout from 'components/pageLayout';
import { Helmet } from 'react-helmet'
import AdminSubNav from 'components/navigations/admin-subnavigation/admin-subnavigation';
import AdminImagesBlock from 'components/block-elements/admin-images/admin-images';

const customBettingImages = () => {
    return (
        <>
            <Helmet title='Admin Panel Betting'></Helmet>
            <PageLayout>
                <AdminSubNav />
                <AdminImagesBlock imageType={4}/>
            </PageLayout>
        </>
      );
}

export default customBettingImages;